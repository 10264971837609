<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.5 6H11.5C6.4 6 3 8.55882 3 14.5294V26.4706C3 32.4412 6.4 35 11.5 35H28.5C33.6 35 37 32.4412 37 26.4706V14.5294C37 8.55882 33.6 6 28.5 6ZM29.299 16.3888L23.978 20.6535C22.856 21.5576 21.428 22.0012 20 22.0012C18.572 22.0012 17.127 21.5576 16.022 20.6535L10.701 16.3888C10.157 15.9453 10.072 15.1265 10.497 14.5806C10.939 14.0347 11.738 13.9324 12.282 14.3759L17.603 18.6406C18.895 19.6812 21.088 19.6812 22.38 18.6406L27.701 14.3759C28.245 13.9324 29.061 14.0176 29.486 14.5806C29.928 15.1265 29.843 15.9453 29.299 16.3888Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'EmailIcon',
    };
</script>
