<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.6085 6.2592C36.4545 6.13368 36.2692 6.05032 36.0714 6.01666C35.8736 5.98301 35.6702 6.00049 35.4817 6.06772C29.5345 8.15921 11.2458 14.7036 3.76938 17.3354C3.53892 17.412 3.33966 17.5588 3.20127 17.7541C3.06288 17.9493 2.9926 18.1832 3.00062 18.4204C3.00863 18.6576 3.09454 18.8855 3.24581 19.0715C3.39708 19.2574 3.60564 19.3918 3.84078 19.4536C7.19445 20.4034 11.5946 21.736 11.5946 21.736C11.5946 21.736 13.6515 27.6532 14.7247 30.6684C14.7893 30.8562 14.9006 31.0258 15.0489 31.1612C15.1972 31.2966 15.3778 31.394 15.5743 31.4446C15.7752 31.4949 15.9858 31.4958 16.1871 31.4471C16.3885 31.3984 16.5744 31.3014 16.728 31.1654C18.445 29.6142 21.11 27.2175 21.11 27.2175C21.11 27.2175 26.1717 30.7556 29.0424 32.699C29.2677 32.8522 29.5277 32.9509 29.8001 32.9858C30.0724 33.0207 30.3492 32.9911 30.6075 32.8998C30.8625 32.8099 31.0902 32.6586 31.2699 32.4607C31.4496 32.2628 31.5755 32.0247 31.6361 31.7671C32.8702 26.2508 35.8572 12.2983 36.9751 7.27874C37.0175 7.09237 37.0056 6.89806 36.9408 6.71791C36.876 6.53776 36.761 6.37902 36.6085 6.2592ZM30.7776 11.3313L16.3522 23.7317L15.8247 28.4643L13.4457 20.9871C13.4457 20.9871 24.2222 14.4246 30.2946 10.7297C30.3797 10.6782 30.481 10.6592 30.5797 10.6752C30.6785 10.6912 30.7677 10.7418 30.8311 10.8173C30.8924 10.893 30.9212 10.9886 30.9112 11.0845C30.9012 11.1805 30.8533 11.2692 30.7776 11.3313Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'TelegramIcon',
    };
</script>
