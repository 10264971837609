<template>
    <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.4665 21.0148L7.34907 17.6684H16.0841C17.2474 17.6684 18.182 16.7205 18.182 15.5406V5.03684C18.182 3.85695 17.2474 2.90906 16.0841 2.90906H3.91629C2.75295 2.90906 1.81836 3.85695 1.81836 5.03684V20.647C1.81836 21.0146 2.19984 21.208 2.46682 21.0146L2.4665 21.0148ZM10.6864 13.9543C10.6864 14.1865 10.5148 14.3605 10.2859 14.3605H9.25598C9.02709 14.3605 8.85546 14.1865 8.85546 13.9543V13.2774C8.85546 13.0452 9.02709 12.8711 9.25598 12.8711H10.2859C10.5148 12.8711 10.6864 13.0452 10.6864 13.2774V13.9543ZM7.99733 6.79743C8.49316 6.41052 9.14162 6.21715 9.94267 6.21715C10.8009 6.21715 11.4875 6.42999 11.9833 6.87484C12.4792 7.31968 12.7271 7.91941 12.7271 8.65446C12.7271 9.11875 12.5936 9.54427 12.3266 9.9312C12.0596 10.3181 11.7162 10.6276 11.2967 10.8403C11.0678 10.9951 10.9152 11.1692 10.8199 11.3432C10.7628 11.4592 10.7245 11.614 10.7055 11.8073C10.6865 11.9621 10.5719 12.0588 10.4193 12.0588H9.16052C8.9889 12.0588 8.85534 11.9233 8.87437 11.7493C8.91244 11.3818 8.98873 11.1109 9.14133 10.8982C9.31296 10.6468 9.63716 10.3372 10.114 10.0084C10.3619 9.85363 10.5526 9.66026 10.6861 9.42811C10.8197 9.19596 10.896 8.9445 10.896 8.63498C10.896 8.32546 10.8197 8.07401 10.6481 7.89993C10.4764 7.70655 10.2475 7.62917 9.94237 7.62917C9.69444 7.62917 9.50376 7.70655 9.332 7.86132C9.23667 7.95801 9.16038 8.07416 9.12215 8.22877C9.06489 8.42214 8.89326 8.53829 8.7026 8.51899H7.53958C7.38699 8.51899 7.27262 8.38353 7.29166 8.24823C7.32973 7.60986 7.55862 7.12626 7.99735 6.79741L7.99733 6.79743Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'QuestionIcon',
    };
</script>
