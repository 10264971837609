<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.1111 3H12.8889C6.66667 3 4 6.4 4 11.5V28.5C4 33.6 6.66667 37 12.8889 37H27.1111C33.3333 37 36 33.6 36 28.5V11.5C36 6.4 33.3333 3 27.1111 3ZM12.8889 20.425H20C20.7289 20.425 21.3333 21.003 21.3333 21.7C21.3333 22.397 20.7289 22.975 20 22.975H12.8889C12.16 22.975 11.5556 22.397 11.5556 21.7C11.5556 21.003 12.16 20.425 12.8889 20.425ZM27.1111 29.775H12.8889C12.16 29.775 11.5556 29.197 11.5556 28.5C11.5556 27.803 12.16 27.225 12.8889 27.225H27.1111C27.84 27.225 28.4444 27.803 28.4444 28.5C28.4444 29.197 27.84 29.775 27.1111 29.775ZM31.5556 15.325H28C25.2978 15.325 23.1111 13.234 23.1111 10.65V7.25C23.1111 6.553 23.7156 5.975 24.4444 5.975C25.1733 5.975 25.7778 6.553 25.7778 7.25V10.65C25.7778 11.823 26.7733 12.775 28 12.775H31.5556C32.2844 12.775 32.8889 13.353 32.8889 14.05C32.8889 14.747 32.2844 15.325 31.5556 15.325Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'DocumentIcon',
    };
</script>
